import React from 'react';
//import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './App.css';
import store from './app/store';
import { setNetworkState } from './features/network/networkSlice.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay } from '@fortawesome/free-solid-svg-icons'

class EkamChannelButtonIcon extends React.Component {
	render() {
		try {
			var icon = faPlay;
			var color = 'var(--primary-color)';
			if(this.props.irState === "INPROGRESS") {
				color = "yellow";
			}

			if(this.props.disabled) {
				return <div></div>;
			};

			return (
				<button className="btn mx-auto channel" onClick={this.props.onClick} disabled={this.props.disabled}>
					<FontAwesomeIcon icon={icon} size="1x" color={color}/>
				</button>
			);
		} catch (err) {
			console.log("EkamChannelButtonIcon Error:",err.message);
			return <div></div>;
		}
	} 
}

class EkamChannelButton extends React.Component {
  	constructor(props) {
		super(props);
		this.onClick = this.onClick.bind(this);
		this.updateStateFromStore = this.updateStateFromStore.bind(this);
		this.state = {irState:"OFF"};
	}

	onClick() {
		if(!this.props.device)
			return;
		if(this.props.number === -1)
			return;

		if(this.state.irState === "INPROGRESS")
			return;
		this.setState({irState:"INPROGRESS"});

		var id = this.props.number;

		var iotarg = {
			deviceid: this.props.device.deviceId,
			remoteid: this.props.device.remoteId,
			number: this.props.number,
			name: this.props.name,
		};
		id = this.props.device.deviceId + "-channel-" + this.props.device.remoteId + "-" + this.props.number;
		var storearg = {
			id: id,
			value: "INPROGRESS",
		};
		store.dispatch(setNetworkState(storearg));
		if(this.props.sendMessage)
			this.props.sendMessage("play-channel", iotarg, id, 8000);
	}

	updateStateFromStore() {
		var state = store.getState();
		if(this.props.device) {
			//console.log("state:",JSON.stringify(state));
			if(state.networkState) {
				var id = this.props.device.deviceId + "-channel-" + this.props.device.remoteId + "-" + this.props.number;
				var networkState = state.networkState[id];
				if(networkState) {
					this.setState({irState:networkState});
				}
			}
		}
	}

	componentDidMount() {
		this.unsubscribeStore = store.subscribe(this.updateStateFromStore);
		this.updateStateFromStore();
	}

	componentWillUnmount() {
		this.unsubscribeStore();
	}

	render() {
		try {
			var disabled = false;
			if(this.props.number === -1)
				disabled = true;
			return(
					<EkamChannelButtonIcon bkey={this.props.bkey} irState={this.state.irState} onClick={this.onClick} disabled={disabled}/>
			  );
		} catch (err) {
			console.log("EkamChannelButtonIcon Error:",err.message);
			return <div></div>;
		}
	}
}

export default EkamChannelButton;
