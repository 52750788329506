import React from 'react';
import Websocket from './react-websocket.js';
import { setNetworkState } from './features/network/networkSlice.js';
import store from './app/store';
import { withAlert } from 'react-alert'
import { Auth } from 'aws-amplify';

const WebSocketContext = React.createContext({
  sendMessage: null,
});

var mode = "prod";
const keepAlive = "KEEP-ALIVE";

if(mode === "test") {
	var demo = require('./ekam.json');
	console.log("demo:",demo);
}

class EkamWebSocket extends React.Component {
  	constructor(props) {
		super(props);
		this.onOpen = this.onOpen.bind(this);
		this.onClose = this.onClose.bind(this);
		this.onMessage = this.onMessage.bind(this);
		this.sendMessage = this.sendMessage.bind(this);
		this.getIdJwtToken = this.getIdJwtToken.bind(this);
		this.getAccessJwtToken = this.getAccessJwtToken.bind(this);
		this.isGuestUser = this.isGuestUser.bind(this);
		this.updateStateFromStore = this.updateStateFromStore.bind(this);
		this.ws = null;
		this.state = {token:this.props.token, key:1};
	}

	showTime() {
		console.log("TIME: ",Date().toLocaleString());
	}

	onOpen() {
		console.log("WEBSOCKET: Connected");
		this.sendMessage("discovery","","discovery",12000);
		this.setMyTimer(keepAlive, 240000);
	}

	getIdJwtToken = async () => {
		try {
		  const session = await Auth.currentSession();
		  return session.getIdToken().getJwtToken();
		} catch(e) {
			console.log("Exception while getting token:",e);
		 	return null;
		}
	};

	getAccessJwtToken = async () => {
		try {
		  const session = await Auth.currentSession();
		  return session.getAccessToken().getJwtToken();
		} catch(e) {
			console.log("Exception while getting token:",e);
		 	return null;
		}
	};

	isGuestUser = async () => {
		try {
			const currentUserInfo = await Auth.currentUserInfo();
			if(currentUserInfo) {
				//console.log("UserInfo:",currentUserInfo);
				var parentUserId = "dummy";
				if(currentUserInfo.attributes)
					parentUserId = currentUserInfo.attributes['custom:finalParentUserId'];
				else 
					if(this.props.onMessage)
						this.props.onMessage("signout");
				
				if(parentUserId)
					return true;
				else
					return false;
			} else {
				console.log("currentUserInfo null");
				return true;
			}
		} catch(e) {
			console.log("Exception while getting user guest status:",e);
			return true;
		}
	};

	onClose = async (code, reason) => {
		console.log("WEBSOCKET: Connection Closed, Code:" + code + ", Reason:"+ reason);
		this.showTime();
		this.clearMyTimer(keepAlive);
		//code 1001 is Going Away
		if(this.ws) {
			if(code === 1001) {
				var token = await this.getAccessJwtToken();
				if(token !== null) {
					//console.log("NewToken:", token);
					this.setState({token:token});
				}
			} else {
				var errorMessage = "Connection with server lost! Code:" + code + ", Reason:" + reason + ".";
				if(this.props.onMessage)
					this.props.onMessage("error", {title:"Page Refresh Required!", body:errorMessage});
			}
		}
	}

	onError(e) {
		console.log("WEBSOCKET: Error:", e);
		this.showTime();
		var errorMessage = "Connection with server lost! Error:" + e + ".";
		if(this.props.onMessage)
			this.props.onMessage("error", {title:"Page Refresh Required", body:errorMessage});
	}

	onMessage(msg) {
		var message = JSON.parse(msg);
		console.log("WEBSOCKET: Received Action:",message.action);
		this.showTime();
		//console.log("WEBSOCKET: Received Message:",msg);
		this.setMyTimer(keepAlive, 240000);
		switch(message.action) {
			case keepAlive:
				this.clearMyTimer("keep-alive-message");
				return;
			case "discovery-response":
				this.clearMyTimer("discovery");
				break;
			case "switch-status":
				this.clearMyTimer("get-switch-state");
				break;
			default:
				break;
		}
		if(this.props.onMessage)
			this.props.onMessage(message.action, message.message);
	}

	async sendMessage(action, message, id, interval) {
		try {
			var isGuest = await this.isGuestUser();
			this.showTime();
			if(this.ws) {
				switch(action) {
					case "discovery":
						if(this.props.onMessage) {
							this.props.onMessage("show-progress", "");
						}
						break;
					case "refresh":
						var key = this.state.key;
						key = key +1;
						var token = await this.getAccessJwtToken();
						if(token !== null) {
							this.setState({token:token, key:key});
						} else {
							this.setState({key:key});
						}
						if(this.props.onMessage) {
							this.props.onMessage("show-progress", "");
						}
						return;
					case "set-switch-state":
					case "run-scene":
					case "send-ir-code":
					case "play-channel":
						if(isGuest) {
    						const myalert = this.props.alert;
							myalert.error("Operation is not allowed for this user!");
							var storearg = {
								id: id,
								value: "NORMAL",
							};
							store.dispatch(setNetworkState(storearg));
							return;
						}
						break;
					default:
						break;
				}
				var msg = {action:action, message:message, userid:this.props.userid, token:this.state.token};
				var msgToSend = JSON.stringify(msg);
				console.log("WEBSOCKET: Sending Action:",action);
				//console.log("WEBSOCKET: Sending Action:",action, "Message:",message);
				await this.ws.sendMessage(msgToSend);
				if(id) {
					this.setMyTimer(id, interval);
				}
			}
		} catch(e) {
			this.onError(e);
		}
	}

	setMyTimer(id, interval) {
		var timer = null;
		if(this.state)
			timer = this.state.activeTimer;
		if(timer) {
			if(timer[id]) {
				console.log("Timer["+id+"], Clear");
				clearTimeout(timer[id]);
			}
		} else {
			timer = [];
		}
		timer[id] = setTimeout(this.handleTimeout, interval, this, id);
		this.setState({activeTimer:timer});
		console.log("Timer["+id+"], Set");
	}

	clearMyTimer(id) {
		var timer = null;
		if(this.state)
			timer = this.state.activeTimer;
		if(timer) {
			if(timer[id]) {
				console.log("Timer["+id+"], Clear");
				clearTimeout(timer[id]);
				timer[id] = null;
				this.setState({activeTimer:timer});
			}
		}
	}

	handleTimeout(me, id) {
		var timer = null;
    	const myalert = me.props.alert;
		if(me.state)
			timer = me.state.activeTimer;
		if(timer) {
			if(timer[id]) {
				console.log("Timer["+id+"], Kicked");
				timer[id] = null;
				me.setState({activeTimer:timer});
				switch(id) {
					case keepAlive:
						me.sendMessage(keepAlive,"","keep-alive-message",12000);
						break;
					case "keep-alive-message":
						console.log("keep alive message timeout:");
						me.showTime();
						var errorMessage = "Timeout occurred while waiting for keep alive response. Check network and try again!";
						if(me.props.onMessage)
							me.props.onMessage("error", {title:"Network error!", body:errorMessage});
						break;
					case "discovery":
						console.log("discovery timeout:");
						me.showTime();
						errorMessage = "Timeout occurred while waiting for discovery response. Check network and try again!";
						if(me.props.onMessage)
							me.props.onMessage("error", {title:"Network error!", body:errorMessage});
						break;
					case "get-switch-state":
						console.log("get-switch-state timeout:");
						me.showTime();
						if(me.props.onMessage)
							me.props.onMessage("get-next-switch-status","");
						break;
					default:
						var storearg = {
							id: id,
							value: "NORMAL",
						};
						store.dispatch(setNetworkState(storearg));
						myalert.error("Timeout occurred while waiting for response!");
						break;
				}
			}
		}
	}

	componentDidMount() {
		if(mode === "test") {
			setTimeout(()=>{
				if(this.props.onMessage)
					this.props.onMessage("discovery-response", demo);
			}, 1000);
		} else {
			if(this.props.getRef) {
				this.props.getRef(this.sendMessage);
			}
		}
		this.unsubscribeStore = store.subscribe(this.updateStateFromStore);
		this.updateStateFromStore();
	}

	componentWillUnmount() {
		this.ws = null;
		this.unsubscribeStore();

		if(this.state && this.state.activeTimer) {
			var ids = Object.keys(this.state.activeTimer);
			if(ids) {
				for(var i=0; i<ids.length; i++) {
					this.clearMyTimer(ids[i]);
				}
			}
		}
	}

	updateStateFromStore() {
		var state = store.getState();
		if(state.networkState) {
			if(this.state && this.state.activeTimer) {
				var ids = Object.keys(this.state.activeTimer);
				if(ids) {
					for(var i=0; i<ids.length; i++) {
						if(state.networkState[ids[i]] === "NORMAL")
							this.clearMyTimer(ids[i]);
					}
				}
			}
		}
	}

	render() {
		if(mode === "test") {
			return <div/>
		} else {
			return <Websocket
						url="wss://f7m2lzdkcj.execute-api.ap-south-1.amazonaws.com/beta" 
						protocol={this.state.token}
						onMessage={this.onMessage}
						onOpen={this.onOpen}
						onClose={this.onClose}
						mykey={this.state.key}
						debug={true}
						ref={Websocket => {
								this.ws = Websocket;
							}}
					/>
		}
	}
}

export {WebSocketContext};
export default withAlert()(EkamWebSocket);
