import React from 'react';
//import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './App.css';
import store from './app/store';
import { setNetworkState } from './features/network/networkSlice.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons'

class EkamWifiButtonIcon extends React.Component {
	render() {
		try {
			var bkey = this.props.bkey.toUpperCase();
			var icon = faPlusCircle;
			var color = 'var(--my-text-color)';
			var classNameStr = "buttonblank";
			var isIcon = true;
			var size="1x";
			switch(bkey) {
				case "KEY_FAN_SPEED_UP":
					icon = faPlusCircle;
					size = "lg";
					break;
				case "KEY_FAN_SPEED_DOWN":
					icon = faMinusCircle;
					size = "lg";
					break;
				default:
					isIcon = false;
					break;
			}
			if(this.props.switchState === "INPROGRESS") {
				classNameStr = "buttonprogress";
				color = "black";
			}
			if(this.props.disabled) {
				return <div></div>;
			};
			if(isIcon) {
				return (
					<h3>
						<button className={classNameStr} onClick={this.props.onClick} disabled={this.props.disabled}>
							<FontAwesomeIcon icon={icon} size={size} color={color}/>
						</button>
					</h3>
				);
			} else {
				var buttonName = "";
				if(this.props.switchState === "INPROGRESS") {
					classNameStr = "textbuttonprogress";
				} else {
					classNameStr = "textbutton";
				}
				return (
					<h6>
						<button className={classNameStr} onClick={this.props.onClick} disabled={this.props.disabled}>
							{buttonName}
						</button>
					</h6>
				);
			}
		} catch (err) {
			console.log("EkamWifiButtonIcon Error:",err.message);
			return <div></div>;
		}
	} 
}

class EkamWifiButton extends React.Component {
  	constructor(props) {
		super(props);
		this.onClick = this.onClick.bind(this);
		this.updateStateFromStore = this.updateStateFromStore.bind(this);
		this.state = {switchState:"OFF"};
	}

	onClick() {
		if(!this.props.device)
			return;

		if(this.state.switchState === "INPROGRESS")
			return;
		this.setState({switchState:"INPROGRESS"});

		var gpioNumber = this.props.device.gpioNumber;
		switch(this.props.bkey) {
			case "KEY_FAN_SPEED_UP":
				var lower = (gpioNumber & 0xf0) | 2;
				gpioNumber = (gpioNumber & 0xffff0000) | lower;
				break;
			case "KEY_FAN_SPEED_DOWN":
				lower = (gpioNumber & 0xf0) | 3;
				gpioNumber = (gpioNumber & 0xffff0000) | lower;
				break;
			default:
				break;
		}

		var iotarg = {
			deviceid: this.props.device.deviceId,
			remoteid: this.props.device.remoteId,
			gpioNumber: gpioNumber,
			id: this.props.id,
		};
		var bid = (this.props.device.gpioNumber >> 16) + this.props.id;
		var id = this.props.device.deviceId + "-wifi-button-" + bid;
		var storearg = {
			id: id,
			value: "INPROGRESS",
		};
		store.dispatch(setNetworkState(storearg));
		if(this.props.sendMessage)
			this.props.sendMessage("control-gpio", iotarg, id, 8000);
	}

	updateStateFromStore() {
		var state = store.getState();
		if(this.props.device) {
			//console.log("state:",JSON.stringify(state));
			if(state.networkState) {
				var bid = (this.props.device.gpioNumber >> 16) + this.props.id;
				var id = this.props.device.deviceId + "-wifi-button-" + bid;
				var networkState = state.networkState[id];
				if(networkState) {
					this.setState({switchState:networkState});
				}
			}
		}
	}

	componentDidMount() {
		this.unsubscribeStore = store.subscribe(this.updateStateFromStore);
		this.updateStateFromStore();
	}

	componentWillUnmount() {
		this.unsubscribeStore();
	}

	render() {
		try {
			return(
					<EkamWifiButtonIcon bkey={this.props.bkey} switchState={this.state.switchState} onClick={this.onClick} disabled={false}/>
			  );
		} catch (err) {
			console.log("EkamWifiButtonIcon Error:",err.message);
			return <div></div>;
		}
	}
}

export default EkamWifiButton;
