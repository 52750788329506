import React from 'react';
//import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import EkamSwitch from "./ekamswitch.js";
import EkamIrSwitch from "./ekamirswitch.js";
import EkamWifiButton from "./ekamwifibutton.js";
import EkamSwitchRefresh from "./ekamswitchrefresh.js";
import EkamFanSpeed from "./ekamfanspeed.js";
import EkamSensor from "./ekamsensor.js";
import EkamIrMainCard from "./ekamirmaincard.js";
import EkamIrButton from "./ekamirbutton.js";
import EkamIrACCard from "./ekamiraccard.js";
import EkamChannelCard from "./ekamchannelcard.js";
import {WebSocketContext} from './websocket.js';
import './App.css';
import './splide.css';

class EkamSwitchCard extends React.Component {
	render() {
        return(
				<div className="switchcard">
					<div className="row mb-2">
						<h5 className="col-6 d-flex justify-content-start"><div className="mt-3 ms-4">{this.props.name}</div></h5>
						<div className="col-6 d-flex justify-content-end">
							<WebSocketContext.Consumer>
								{ sendMessage => <div className="row me-2"> 
										<div className="col-6 mt-2">
											<EkamSwitchRefresh device={this.props.device} sendMessage={sendMessage}/>
										</div>
										<div className="col-6 mt-3">
											<EkamSwitch device={this.props.device} sendMessage={sendMessage}/>
										</div>
									</div> }
							</WebSocketContext.Consumer>
						</div>
					</div>
				</div>
          );
	}
}

class EkamIrSwitchCard extends React.Component {
	render() {
        return(
				<div className="switchcard">
					<div className="row mb-2">
						<h5 className="col-6 d-flex justify-content-start"><div className="mt-3 ms-4">{this.props.name}</div></h5>
						<div className="col-6 d-flex justify-content-end">
							<WebSocketContext.Consumer>
								{ sendMessage => <div className="row me-2"> 
										<div className="col-6 mt-3">
											<EkamIrSwitch device={this.props.device} sendMessage={sendMessage}/>
										</div>
									</div> }
							</WebSocketContext.Consumer>
						</div>
					</div>
				</div>
          );
	}
}

class EkamDeviceCard extends React.Component {
	render() {
		try {
			var device = this.props.device;
			var category = this.props.category;
			var deviceCard = <div></div>;
			var name = device.name;
			switch(category) {
				case "Switches":
					switch(device.remoteType) {
						case 10:
							return <EkamIrSwitchCard name={name} device={device}/>;
						case 2:
						default:
							return <EkamSwitchCard name={name} device={device}/>;
					}
				case "Fans":
					switch(device.remoteType) {
						case 11:
							deviceCard = 
									<div className="switchcard">
										<div className="row mt-4 mb-2">
											<h5 className="col d-flex justify-content-start ms-4">{name}</h5>
											<div className="col d-flex justify-content-end me-4">
												<WebSocketContext.Consumer>
													{ sendMessage => <EkamIrSwitch device={this.props.device} sendMessage={sendMessage}/> }
												</WebSocketContext.Consumer>
											</div>
										</div>
										<div className="row mt-4 mb-4">
											<div className="col d-flex justify-content-start ms-4">
												<WebSocketContext.Consumer>
													{ sendMessage => <EkamIrButton device={this.props.device} id={this.props.device.gpioNumber+2} bkey={"KEY_FAN_SPEED_DOWN"} sendMessage={sendMessage}/> }
												</WebSocketContext.Consumer>
											</div>
											<div className="col d-flex justify-content-end me-4">
												<WebSocketContext.Consumer>
													{ sendMessage => <EkamIrButton device={this.props.device} id={this.props.device.gpioNumber+1} bkey={"KEY_FAN_SPEED_UP"} sendMessage={sendMessage}/> }
												</WebSocketContext.Consumer>
											</div>
										</div>
									</div>
							return deviceCard;
						case 2:
						default:
							deviceCard = 
									<div className="switchcard">
										<div className="row mt-4 mb-2">
											<div className="col-1"/>
											<h5 className="col-4 d-flex justify-content-start">{name}</h5>
											<div className="col-6 d-flex justify-content-end">
												<WebSocketContext.Consumer>
													{ sendMessage => <div className="row"> 
															<div className="col-6">
																<EkamSwitchRefresh device={this.props.device} sendMessage={sendMessage}/>
															</div>
															<div className="col-6">
																<EkamSwitch device={this.props.device} sendMessage={sendMessage}/>
															</div>
														</div> }
												</WebSocketContext.Consumer>
											</div>
										</div>
										<div className="row mt-4 mb-4">
											<div className="col d-flex justify-content-start ms-4">
												<WebSocketContext.Consumer>
													{ sendMessage => <EkamWifiButton device={this.props.device} id={3} bkey={"KEY_FAN_SPEED_DOWN"} sendMessage={sendMessage}/> }
												</WebSocketContext.Consumer>
											</div>
											<div className="col mx-auto my-auto">
												<EkamFanSpeed device={this.props.device}/>
											</div>
											<div className="col d-flex justify-content-end me-4">
												<WebSocketContext.Consumer>
													{ sendMessage => <EkamWifiButton device={this.props.device} id={2} bkey={"KEY_FAN_SPEED_UP"} sendMessage={sendMessage}/> }
												</WebSocketContext.Consumer>
											</div>
										</div>
									</div>
							return deviceCard;
					}
				case "Appliances":
					switch(device.remoteType) {
						case 2:
						case 10:
						default:
							deviceCard = 
									<div className="switchcard">
										<div className="remoteheader">
											<div className="row mt-3 mb-2">
												<h5 className="col mx-auto my-2">{name}</h5>
											</div>
										</div>
										<EkamIrMainCard device={device} buttons={device.buttons}/>
										<EkamIrACCard device={device} buttons={device.buttons}/>
										<EkamChannelCard device={device}/>
									</div>
							return deviceCard;
					}
				case "Sensors":
					return <WebSocketContext.Consumer>
								{ sendMessage => <EkamSensor device={this.props.device} sendMessage={sendMessage}/> }
							</WebSocketContext.Consumer>
				default:
					break;
			}
			return ( 
							<div className="card">
								{deviceCard}
							</div>
					);
		} catch (err) {
			console.log("EkamDeviceCard Error:",err.message);
			return <div></div>;
		}
	}
}

export default EkamDeviceCard;
