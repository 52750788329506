import React from 'react';
//import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faClock } from '@fortawesome/free-solid-svg-icons'
//import { faMinusSquare, faPlusSquare } from '@fortawesome/free-regular-svg-icons'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import EkamSceneSwitch from "./ekamsceneswitch.js";
import {EkamCategoryIcon} from './ekamutils.js';
import {WebSocketContext} from './websocket.js';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import './App.css';
import './splide.css';

class EkamSceneCategoryCard extends React.Component {
  	constructor(props) {
		super(props);
		this.onClick = this.onClick.bind(this);
	}

	onClick() {
		if(this.props.onClick)
			this.props.onClick(this.props.json.page);
	}

	render() {
		try {
			var layout = this.props.json.page;
			return (
				<SplideSlide>
					<div className="categorycard" onClick={this.onClick}>
						<div className="row ms-1 me-1">
							<EkamCategoryIcon name={"SCENES"}/>
							<h5 className="col-auto mt-auto pt-2">{layout.name}</h5>
						</div>
					</div>
				</SplideSlide>
			);
		} catch (err) {
			console.log("EkamCategoryCard Error:",err.message);
			return <div></div>;
		}
	}
}

class EkamScenePageSlider extends React.Component {
  	constructor(props) {
		super(props);
		this.onScenePageClick = this.onScenePageClick.bind(this);
	}

	onScenePageClick(json) {
		if(this.props.onScenePageClick) {
			this.props.onScenePageClick(json);
		}
	}

	render() {
		try {
			var scenes = this.props.json;
			var card;
			if(scenes) {
				var categoryRows = [];
				for(var i=0; i<scenes.length; i++) {
					card = <EkamSceneCategoryCard json={{page:scenes[i]}} key={scenes[i].name} onClick={this.onScenePageClick}/>;
					categoryRows.push(card);
				}
			}
			return ( 
						<Splide
							options={ {
									type : 'slide',
									rewind : true,
									autoWidth: true,
									perMove: 2
								} }
							>
							{categoryRows}
						</Splide>
					);
		} catch (err) {
			console.log("EkamScenePageSlider Error:",err.message);
			return <div></div>;
		}
	}
}

class EkamSceneCard extends React.Component {
	render() {
		var scene = this.props.scene;
		var schedule = getSchedule(scene);
		var icon = faClock;
		if(schedule == null) {
			schedule = "No Schedule";
			icon = faBars;
		}

		function getSchedule(scene) {
			var dayMask = scene.day;
			var hour = scene.hour;
			var minute = scene.minute;
			var description = null;
			switch (dayMask) {
				case 0:
					return description;
				case 0x73:
					description = "WeekDays ";
					break;
				case 0x7f:
					description = "Everyday ";
					break;
				default:
					description = getDays(dayMask);
					break;
			}

			var time;
			time = hour + ":" + minute + " Hrs";
			return description + "- " + time;
		}

		function getDays(dayMask) {
			var days = "";
			for( var weekday=4; weekday<7; weekday++) {
				if((dayMask & (1 << weekday)) !== 0) {
					days += getDayName(weekday);
				}
			}

			for( weekday=0; weekday<4; weekday++) {
				if((dayMask & (1 << weekday)) !== 0) {
					days += getDayName(weekday);
				}
			}
			return days;
		}

		function getDayName(weekday) {
			switch (weekday) {
				case 0: return "Th ";
				case 1: return "F ";
				case 2: return "Sa ";
				case 3: return "Su ";
				case 4: return "M ";
				case 5: return "Tu ";
				case 6: return "W ";
				default: return "";
			}
		}
		
		if(scene) {
			return ( 
						<div className="scenecard">
							<div className="row mt-3 mb-2">
								<h5 className="col-auto d-flex justify-content-start ms-4">{scene.name}</h5>
								<div className="col me-4 d-flex justify-content-end">
									<WebSocketContext.Consumer>
										{ sendMessage => <EkamSceneSwitch scene={this.props.scene} sendMessage={sendMessage}/> }
									</WebSocketContext.Consumer>
								</div>
							</div>
							<div className="row mt-3 mb-3">
								<div className="col d-flex justify-content-start ms-4">
									<FontAwesomeIcon icon={icon} size="lg"/>
								</div>
								<div className="col-auto me-4 d-flex jusity-content-end">
									<h6>{schedule}</h6>
								</div>
							</div>
						</div>
					);
		}
	}
}

class EkamSceneViewer extends React.Component {
	render() {
		try {
			var page = this.props.page;
			if(page) {
				var cards = [];
				var scenes = page.scenes;
				if(scenes) {
					for (var i = 0; i < scenes.length; i++) {
						var scene = scenes[i];
						var card = <EkamSceneCard scene={scene} key={i}/>;
						cards.push(card);
					}
				}
			}
			return ( 
						<div className="card-deck mx-auto justify-content-center text-center mt-4">
							{cards}
						</div>
					);
		} catch (err) {
			console.log("EkamSceneViewer Error:",err.message);
			return <div></div>;
		}
	}
}

export {EkamScenePageSlider, EkamSceneViewer};
