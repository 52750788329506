import React from 'react';
import Amplify from 'aws-amplify';
import { AmplifyAuthenticator, AmplifySignIn, AmplifySignOut } from '@aws-amplify/ui-react';
import { AuthState } from '@aws-amplify/ui-components';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import homebg from './images/home.png'
import './App.css';
import EkamUI from './ekamui.js';
import EkamWebSocket from './websocket.js';
import {WebSocketContext} from './websocket.js';
import store from './app/store';
import { setSensorState } from './features/sensor/sensorSlice.js';
import { setNetworkState } from './features/network/networkSlice.js';
import { setSwitchState } from './features/switch/switchSlice.js';
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import { Navbar, Nav } from 'react-bootstrap';
import AlertTemplate from './ekamalert.js';
import { Auth } from 'aws-amplify';
import { Hub } from 'aws-amplify'

const alertOptions = {
  timeout: 5000,
  offset: '30px',
  // you can also just use 'scale'
  transition: transitions.SCALE
} 

Amplify.configure({
	Auth: {
		// REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
		identityPoolId: 'us-east-1:2d132986-3f84-41fa-8cb2-e0bf33b25779',
		// REQUIRED - Amazon Cognito Region
		region: 'us-east=1',
		// OPTIONAL - Amazon Cognito User Pool ID
		userPoolId: 'us-east-1_bMLI9f11m',
		// OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
		userPoolWebClientId: '5rd8ribonmgg6qpnignieil7ah',
		// OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
		mandatorySignIn: true,
	}
});

function getSwitchDevices(message) {
	var deviceIdList = [];
	try {
		if(!message || !message.rooms)
			return null;
		console.log("getSwitchDevices:",message);
		for(var r=0; r<message.rooms.length; r++) {
			var room = message.rooms[r];
			for(var c=0; c<room.categories.length; c++) {
				var category = room.categories[c];
				for(var d=0; d<category.devices.length; d++) {
					var device = category.devices[d];
					if(device.remoteType === 2) {
						if(!deviceIdList.includes(device.deviceId)) {
							deviceIdList.push(device.deviceId);
						}
					}
				}
			}
		}
	} catch (e) {
	}
	return deviceIdList;
}

function getSwitchState(deviceId, sendMessage) {
	var message = {deviceid: deviceId};
	if(sendMessage)
		sendMessage("get-switch-state",message,"get-switch-state",12000);
}

function getMergedDiscoveryMessage(message) {
	var merged = message[0];
	for( var i=1; i<message.length; i++) {
		var current = message[i];
		for( var r=0; r<current.rooms.length; r++) {
			merged.rooms.push(current.rooms[r]);
		}
	}
	return merged;
}

class EkamGlobalSignOut extends React.Component {
  	constructor(props) {
		super(props);
		this.onClick = this.onClick.bind(this);
	}

	async onClick() {
	   	try {
			console.log("signing out globally");
			if(this.props.sendMessage)
				await this.props.sendMessage("global-signout");
			await Auth.signOut({ global: true });
			console.log("signing out complete");
		} catch (error) {
			console.log('error signing out: ', error);
		}
	}

	render() {
		return <button className="ms-auto me-4 btn globalsignout" onClick={this.onClick}><div>GLOBAL SIGN OUT</div></button>
	}	
}

class EkamWebApp extends React.Component {
  	constructor(props) {
		super(props);
		this.state = {message:null, updateState:null};
		this.onMessage = this.onMessage.bind(this);
		this.onAuthEvent = this.onAuthEvent.bind(this);
		this.getNextDeviceSwitchState = this.getNextDeviceSwitchState.bind(this);
		this.deviceIdList = null;
	}

	componentDidMount() {
		Hub.listen('auth', this.onAuthEvent);
	}

	componentWillUnmount() {
		Hub.remove('auth', this.onAuthEvent);
	}

	onAuthEvent(authData) {
		this.props.handleAuthEvent(authData.payload.event, authData);
	}
	
	getNextDeviceSwitchState() {
		if(this.deviceIdList) {
			if(this.deviceIdIndex >= this.deviceIdList.length) {
				this.deviceIdList = null;
				this.deviceIdIndex = 0;
				if(this.state.updateState) {
					this.state.updateState(this.state.message);
				}
			} else {
				getSwitchState(this.deviceIdList[this.deviceIdIndex], this.state.sendMessage);
				this.deviceIdIndex++;
			}
			return 0;
		}
		return 1;
	}

	onMessage(action, message) {
		//console.log("received action:",action);
		switch(action) {
			case "discovery-response":
				message = getMergedDiscoveryMessage(message);
				this.setState({message:message});
				//Fetch status of all devices
				this.deviceIdList = getSwitchDevices(message);
				this.deviceIdIndex = 0;
				if(this.deviceIdList && this.deviceIdList.length >= 1) {
					getSwitchState(this.deviceIdList[this.deviceIdIndex], this.state.sendMessage);
					this.deviceIdIndex++;
				} else {
					if(this.state.updateState) {
						this.state.updateState(this.state.message);
					}
				}
				break;

			case "switch-status":
				console.log("Processing switch status :",message.switchStatus);
				var switchStatus = message.switchStatus;
				if(message.responseCode !== 0) {
					console.log("Response Code :",message.responseCode);
					this.getNextDeviceSwitchState();
					break;
				}
				for(var i=0; i<16; i++) {
					var deviceId = message.deviceid;
					var statusIndex = i;
					if(i >= 8)
						statusIndex += 8;
					var id = deviceId + "-" + statusIndex;
					var switchrefreshid = deviceId + "-switch-refresh-" + statusIndex;
					var value = "OFF";
					if((switchStatus>>statusIndex) &1)
						value = "ON";
					var storearg = {
						deviceid: deviceId,
						buttonid: statusIndex,
						value: value,
					};
					store.dispatch(setSwitchState(storearg));
					storearg = {
						id: id,
						value: "NORMAL",
					};
					store.dispatch(setNetworkState(storearg));
					storearg = {
						id: switchrefreshid,
						value: "NORMAL",
					};
					store.dispatch(setNetworkState(storearg));
				}

				//fan1 state
				value = "ON";
				if((switchStatus>>11) &1)
					value = "OFF";
				storearg = {
					deviceid: deviceId,
					buttonid: 8,
					value: value,
				};
				console.log("fan arg:",storearg);
				store.dispatch(setSwitchState(storearg));
				id = deviceId + "-8";
				storearg = {
					id: id,
					value: "NORMAL",
				};
				store.dispatch(setNetworkState(storearg));
				switchrefreshid = deviceId + "-switch-refresh-" + 8;
				storearg = {
					id: switchrefreshid,
					value: "NORMAL",
				};
				store.dispatch(setNetworkState(storearg));
				var fanspeedid = deviceId + "-fan-speed-" + 8;
				var speed = ((switchStatus>>8) & 7);
				storearg = {
					id: fanspeedid,
					value: speed,
				};
				store.dispatch(setNetworkState(storearg));

				this.getNextDeviceSwitchState();
				break;
			case "get-next-switch-status":
				if( 0 === this.getNextDeviceSwitchState()) {
					if(this.state.updateState) {
						this.state.updateState({action:"error",message:{title:"Network error!", body:"Timeout occurred while waiting for switch status response. Check network and try again!"}});
					}
				}
				break;
			case "sensor-status":
				console.log("Processing sensor status");
				var sensorStatus = message.sensorStatus;
				//console.log("message:", message);
				//console.log("sensor status:", sensorStatus);
				for(i=0; i<sensorStatus.length; i++) {
					deviceId = message.deviceid;
					if( sensorStatus[i] !== 0xffffffff) {
						var sensorId = (sensorStatus[i] >> 16) & 0xff;
						var sensorValue = sensorStatus[i] & 0xffff;
						console.log("sensor id:", sensorId, "sensor value:", sensorValue);
						id = deviceId + "-sensor-" + sensorId;
						//console.log("Updating store state of id:",id,",Value:",sensorValue);
						storearg = {
							deviceid: deviceId,
							sensorid: sensorId,
							value: {sensorValue:sensorValue, refreshState:"NORMAL"},
						};
						store.dispatch(setSensorState(storearg));
						//Network state of sensor refresh button
						storearg = {
							id: id,
							value: "NORMAL",
						};
						store.dispatch(setNetworkState(storearg));
						//Network state of sensor switch
						storearg = {
							id: deviceId + "-" + sensorId,
							value: "NORMAL",
						};
						store.dispatch(setNetworkState(storearg));
						value = (sensorStatus[i] >> 31) & 1;
						var swValue = "OFF";
						if(value)
							swValue = "ON";
						storearg = {
							deviceid: deviceId,
							buttonid: sensorId,
							value: swValue,
						};
						store.dispatch(setSwitchState(storearg));
					}
				}
				break;
			case "run-scene-response":
				console.log("Processing scene response");
				deviceId = message.deviceid;
				id = deviceId + "-scene-" +  message.sceneId;
				storearg = {
					id: id,
					value: "NORMAL",
				};
				store.dispatch(setNetworkState(storearg));
				break;
			case "control-gpio-response":
				console.log("Processing control-gpio response");
				deviceId = message.deviceid;
				var bid = (message.gpioNumber >> 16) + message.id;
				id = deviceId + "-wifi-button-" + bid;
				storearg = {
					id: id,
					value: "NORMAL",
				};
				store.dispatch(setNetworkState(storearg));
				break;
			case "send-ir-code-response":
				console.log("Processing IR Send response");
				deviceId = message.deviceid;
				id = deviceId + "-ir-" +  message.remoteid + "-" + message.id;
				storearg = {
					id: id,
					value: "NORMAL",
				};
				store.dispatch(setNetworkState(storearg));
				break;
			case "play-channel-response":
				console.log("Processing Play Channel  response");
				deviceId = message.deviceid;
				id = deviceId + "-channel-" +  message.remoteid + "-" + message.number;
				storearg = {
					id: id,
					value: "NORMAL",
				};
				store.dispatch(setNetworkState(storearg));
				break;
			case "show-progress":
			case "error":
				if(this.state.updateState) {
					this.state.updateState({action:action,message:message});
				}
				break;

			case "signout":
				Auth.signOut();
				break;

			default:
				break;
		}
	}

	render() {
		return (
			<div className="cover" style={{ background: `url(${homebg})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', height:'100%'}}>
				<Navbar collapseOnSelect expand="true" bg="fade" variant="light">
				  <Navbar.Brand href="#home"><div className="ms-4 mynavtext">EKAM</div></Navbar.Brand>
				  <Navbar.Toggle className="me-4" aria-controls="responsive-navbar-nav" />
				  <Navbar.Collapse id="responsive-navbar-nav">
					<Nav className="ms-auto me-2 mt-4">
					  <AmplifySignOut className="ms-auto me-4 signout"/>
					</Nav>
					<Nav className="ms-auto me-2 mt-4">
					  <EkamGlobalSignOut sendMessage={this.state.sendMessage}/>
					</Nav>
				  </Navbar.Collapse>
				</Navbar>
				<div className="container">
					<WebSocketContext.Provider value={this.state.sendMessage}>
						<EkamUI setUpdateStateFn={(fn) => this.setState({updateState:fn})}/>
					</WebSocketContext.Provider>
					<EkamWebSocket onMessage={this.onMessage} token={this.props.token} userid={this.props.userid} getRef={(sendMessage) => this.setState({sendMessage:sendMessage})}/>
				</div>
			</div>
		);
	}
}

class EkamAuthStateApp extends React.Component {
  	constructor(props) {
		super(props);
		this.state = {authEvent:null, user:null};
		this.handleAuthEvent = this.handleAuthEvent.bind(this);
		this.handleAuthStateChange = this.handleAuthStateChange.bind(this);
	}

	handleAuthEvent(authEvent, authData) {
		if (authEvent === "signIn") {
			console.log("user successfully signed in!");
			//console.log("user data: ", authData);
			//this.setState({authEvent:authEvent, user:authData});
		}
		if (authEvent === "signOut") {
			console.log("user signed out!");
			this.setState({authEvent:authEvent, user:null});
		}
		if (authData) {
			console.log("authData:", authData);
		}
	};

	handleAuthStateChange(nextAuthState, authData) {
		//console.log("handleAuthStateChange: status:",nextAuthState);
		if (nextAuthState === AuthState.SignedIn) {
			console.log("user successfully signed in!");
			//console.log("user data: ", authData);
			this.setState({authEvent:"signIn", user:authData});
	 	}
	  	if (authData) {
			//console.log("authData: ", authData);
	  	}
	};

	render() {
		return this.state.authEvent === "signIn" && this.state.user ? (
		  <AlertProvider template={AlertTemplate} position={positions.BOTTOM_CENTER} {...alertOptions}>
			<EkamWebApp token={this.state.user.signInUserSession.accessToken.jwtToken} userid={this.state.user.attributes.sub} handleAuthEvent={this.handleAuthEvent}/>
		  </AlertProvider>
		) : (
			<div className="cover" style={{ background: `url(${homebg})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', height:'100%'}}>
				<AmplifyAuthenticator handleAuthStateChange={this.handleAuthStateChange}>
				  <AmplifySignIn 
					headerText="Ekam Home Automation"
					slot="sign-in"
					hideSignUp="true"
				  ></AmplifySignIn>
				</AmplifyAuthenticator>
			</div>
		);
	}
}

/*
const AuthStateApp = () => {
    const [authState, setAuthState] = React.useState();
    const [user, setUser] = React.useState();

    React.useEffect(() => {
        return onAuthUIStateChange((nextAuthState, authData) => {
			//console.log("Auth State Change:",nextAuthState,"\nData:",authData);
            setAuthState(nextAuthState);
            setUser(authData)
        });
    }, []);

  	return authState === AuthState.SignedIn && user ? (
	  <AlertProvider template={AlertTemplate} position={positions.BOTTOM_CENTER} {...alertOptions}>
	  	<EkamWebApp token={user.signInUserSession.accessToken.jwtToken} userid={user.attributes.sub} globalProps={this.props}/>
	  </AlertProvider>
    ) : (
		<div className="cover" style={{ background: `url(${homebg})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', height:'100%'}}>
			<AmplifyAuthenticator>
			  <AmplifySignIn 
				headerText="Ekam Home Automation"
				slot="sign-in"
				hideSignUp="true"
			  ></AmplifySignIn>
			</AmplifyAuthenticator>
		</div>
  	);
}
*/

export default EkamAuthStateApp;
