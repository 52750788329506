import React from 'react';
//import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './App.css';
import store from './app/store';
import { setNetworkState } from './features/network/networkSlice.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPowerOff, faVolumeMute, faArrowLeft, faBars, faCaretLeft, faCaretRight, faCaretUp, faCaretDown, faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons'

class EkamIrButtonIcon extends React.Component {
	render() {
		try {
			var bkey = this.props.bkey.toUpperCase();
			var icon = faPowerOff;
			var color = 'var(--my-text-color)';
			var classNameStr = "buttonblank";
			var isIcon = true;
			var size="1x";
			switch(bkey) {
				case "KEY_POWER":
					icon = faPowerOff;
					color = "red";
					break;
				case "KEY_MUTE":
					icon = faVolumeMute;
					break;
				case "KEY_BACK":
					icon = faArrowLeft;
					break;
				case "KEY_MENU":
					icon = faBars;
					break;
				case "KEY_UP":
					icon = faCaretUp;
					color = 'var(--primary-color)';
					break;
				case "KEY_DOWN":
					icon = faCaretDown;
					color = 'var(--primary-color)';
					break;
				case "KEY_LEFT":
					icon = faCaretLeft;
					color = 'var(--primary-color)';
					break;
				case "KEY_RIGHT":
					icon = faCaretRight;
					color = 'var(--primary-color)';
					break;
				case "KEY_FAN_SPEED_UP":
					icon = faPlusCircle;
					size = "lg";
					break;
				case "KEY_FAN_SPEED_DOWN":
					icon = faMinusCircle;
					size = "lg";
					break;
				default:
					isIcon = false;
					break;
			}
			if(this.props.irState === "INPROGRESS") {
				classNameStr = "buttonprogress";
				color = "black";
			}
			if(this.props.disabled) {
				return <div></div>;
			};
			if(isIcon) {
				return (
					<h3>
						<button className={classNameStr} onClick={this.props.onClick} disabled={this.props.disabled}>
							<FontAwesomeIcon icon={icon} size={size} color={color}/>
						</button>
					</h3>
				);
			} else {
				var buttonName = "";
				if(this.props.irState === "INPROGRESS") {
					classNameStr = "textbuttonprogress";
				} else {
					classNameStr = "textbutton";
				}
				switch(bkey) {
					case "KEY_INPUT":
						buttonName = "INPUT";
						break;
					case "KEY_AC_POWER_ON":
						buttonName = "ON";
						break;
					case "KEY_AC_POWER_OFF":
						buttonName = "OFF";
						break;
					case "KEY_AC_TEMP":
						buttonName = "Set Temperature";
						break;
					default:
						break;
				}
				return (
					<h6>
						<button className={classNameStr} onClick={this.props.onClick} disabled={this.props.disabled}>
							{buttonName}
						</button>
					</h6>
				);
			}
		} catch (err) {
			console.log("EkamIrButtonIcon Error:",err.message);
			return <div></div>;
		}
	} 
}

class EkamIrButton extends React.Component {
  	constructor(props) {
		super(props);
		this.onClick = this.onClick.bind(this);
		this.updateStateFromStore = this.updateStateFromStore.bind(this);
		var id = -1;
		var buttons = this.props.device.buttons;
		for(var i=0; i<buttons.length; i++) {
			var keyToUse = this.props.bkey;
			if(this.props.bkey === "KEY_CHANNEL") {
				id = this.props.number;
				break;
			}
			if(this.props.bkey === "KEY_AC_TEMP")
				keyToUse = "KEY_AC_TEMP_" + this.props.gettemp();
			if(buttons[i].key === keyToUse) {
				id = buttons[i].id;
				break;
			}
		}
		if(this.props.id)
			id = this.props.id;
		this.state = {irState:"OFF", keyid:id};
	}

	onClick() {
		if(!this.props.device)
			return;
		if(this.state.keyid === -1)
			return;

		if(this.state.irState === "INPROGRESS")
			return;
		this.setState({irState:"INPROGRESS"});

		var id = this.state.keyid;
		if(this.props.bkey === "KEY_AC_TEMP") {
			var found = false;
			var buttons = this.props.device.buttons;
			for(var i=0; i<buttons.length; i++) {
				var keyToUse = "KEY_AC_TEMP_" + this.props.gettemp();
				if(keyToUse === buttons[i].key) {
					id = buttons[i].id;
					this.setState({keyid:id});
					found = true;
					break;
				}
			}
			if(!found)
				return;
		}

		var iotarg = {
			deviceid: this.props.device.deviceId,
			remoteid: this.props.device.remoteId,
			key: this.props.bkey,
			id: id,
		};
		console.log("Ir Key:",JSON.stringify(this.props.bkey));
		id = this.props.device.deviceId + "-ir-" + this.props.device.remoteId + "-" + id;
		var storearg = {
			id: id,
			value: "INPROGRESS",
		};
		store.dispatch(setNetworkState(storearg));
		if(this.props.sendMessage)
			this.props.sendMessage("send-ir-code", iotarg, id, 8000);
	}

	updateStateFromStore() {
		var state = store.getState();
		if(this.props.device) {
			//console.log("state:",JSON.stringify(state));
			if(state.networkState) {
				var id = this.props.device.deviceId + "-ir-" + this.props.device.remoteId + "-" + this.state.keyid;
				var networkState = state.networkState[id];
				if(networkState) {
					this.setState({irState:networkState});
				}
			}
		}
	}

	componentDidMount() {
		this.unsubscribeStore = store.subscribe(this.updateStateFromStore);
		this.updateStateFromStore();
	}

	componentWillUnmount() {
		this.unsubscribeStore();
	}

	render() {
		try {
			var bkey = this.props.bkey.toUpperCase();
			var classNameStr = "mainbutton";
			if(this.state.irState === "INPROGRESS")
				classNameStr = "mainbuttonprogress";
			var disabled = false;
			if(this.state.keyid === -1)
				disabled = true;
			switch(bkey) {
				case "KEY_VOLUME_UP":
					return (
							<button className={classNameStr} onClick={this.onClick} disabled={disabled}>
								Vol+
							</button>
						);
				case "KEY_VOLUME_DOWN":
					return (
							<button className={classNameStr} onClick={this.onClick} disabled={disabled}>
								Vol-
							</button>
						);
				case "KEY_CH_UP":
					return (
							<button className={classNameStr} onClick={this.onClick} disabled={disabled}>
								CH+
							</button>
						);
				case "KEY_CH_DOWN":
					return (
							<button className={classNameStr} onClick={this.onClick} disabled={disabled}>
								CH-
							</button>
						);
				case "KEY_OK":
					return (
							<button className={classNameStr} onClick={this.onClick} disabled={disabled}>
								OK
							</button>
						);
				default:
					return(
							<EkamIrButtonIcon bkey={this.props.bkey} irState={this.state.irState} onClick={this.onClick} disabled={disabled}/>
					  );
			}
		} catch (err) {
			console.log("EkamIrButtonIcon Error:",err.message);
			return <div></div>;
		}
	}
}

export default EkamIrButton;
