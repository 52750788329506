import React from 'react';
//import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import {WebSocketContext} from './websocket.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUmbrellaBeach, faFilm, faTv, faChild, faFutbol, faGlobe, faBook, faCocktail, faMusic, faDove, faPlay, faArrowLeft, faTimes} from '@fortawesome/free-solid-svg-icons'
import EkamChannelButton from "./ekamchannelbutton.js";
import './App.css';
import './splide.css';

class EkamChannelCategoryIcon extends React.Component {
  	constructor(props) {
		super(props);
		this.onClick = this.onClick.bind(this);
	}

	onClick() {
		if(this.props.onClick) {
			this.props.onClick(this.props.category);
		}
	}

	render() {
		try {
			var name = this.props.name;
			var nameCaps = name.toUpperCase();
			var icon = faTv;
			var size = "lg";
			if(this.props.size)
				size = this.props.size;
			switch(nameCaps) {
				case "ENTERTAINMENT":
					icon = faUmbrellaBeach;
					break;
				case "MOVIES":
					icon = faFilm;
					break;
				case "KIDS":
					icon = faChild;
					break;
				case "SPORTS":
					icon = faFutbol;
					break;
				case "NEWS":
					icon = faGlobe;
					break;
				case "KNOWLEDGE":
					icon = faBook;
					break;
				case "LIFESTYLE":
					icon = faCocktail;
					break;
				case "MUSIC":
					icon = faMusic;
					break;
				case "SPIRITUAL":
					icon = faDove;
					break;
				case "PLAY":
					icon = faPlay;
					break;
				case "BACK":
					icon = faArrowLeft;
					break;
				case "CLOSE":
					icon = faTimes;
					break;
				default:
					break;
			}
			return (
				<FontAwesomeIcon className="col mt-1" icon={icon} size={size}/>
			);
		} catch (err) {
			console.log("EkamRoomIcon Error:",err.message);
			return <div></div>;
		}
	}
}

class EkamChannelCategoryBox extends React.Component {
  	constructor(props) {
		super(props);
		this.onClick = this.onClick.bind(this);
	}

	onClick() {
		if(this.props.onClick) {
			this.props.onClick(this.props.category);
		}
	}

	render() {
		try {
			var name = this.props.category.name;
			var nameCaps = name.toUpperCase();
			return (
					<button className="channelcat m-2 col-5" onClick={this.onClick}>
						<div className="row mt-2 mb-1">
							<EkamChannelCategoryIcon name={nameCaps}/>
						</div>
						<div className="row mb-2">
							<div className="col channelcattext">
								{name}
							</div>
						</div>
					</button>
			);
		} catch (err) {
			console.log("EkamRoomIcon Error:",err.message);
			return <div></div>;
		}
	}
}

class EkamChannelHomePage extends React.Component {
	render() {
		var categories = this.props.channelCategories;
		var cols = [];
		for(var i=0; i<categories.length; i++) {
			cols.push(<EkamChannelCategoryBox category={categories[i]} key={i} onClick={this.props.onClick}/>);
		}
		return <div className="pb-2 pt-2 mt-3 mb-3"> {cols} </div>
	}
}

class EkamCategoryPage extends React.Component {
  	constructor(props) {
		super(props);
		this.onCloseClick = this.onCloseClick.bind(this);
		this.onBackClick = this.onBackClick.bind(this);
	}

	onCloseClick() {
		console.log("Close clicked!");
		if(this.props.onClick) {
			this.props.onClick(null, "CLOSE");
		}
	}

	onBackClick() {
		console.log("Back clicked!");
		if(this.props.onClick) {
			this.props.onClick(null, "BACK");
		}
	}
	render() {
		var category = this.props.category;
		var cols = [];
		for(var i=0; i<category.subCategories.length; i++) {
			cols.push(<EkamChannelSubCategoryBox subCategory={category.subCategories[i]} key={i} onClick={this.props.onClick}/>);
		}
		return <div className="pb-2 pt-2 mt-4 mb-3 pb-3 channelcat">
					<div className="row mt-2 mb-3">
						<div className="col-1"> </div>
						<button className="col-2 btn" onClick={this.onBackClick}>
							<EkamChannelCategoryIcon name={"BACK"}/>
						</button>
						<div className="col-6 my-auto">
							<div className="channelsubcattext">
								{category.name}
							</div>
						</div>
						<button className="col-2 btn" onClick={this.onCloseClick}>
							<EkamChannelCategoryIcon name={"CLOSE"}/>
						</button>
					</div>
					{cols}
				</div>;
	}
}

class EkamChannelSubCategoryBox extends React.Component {
  	constructor(props) {
		super(props);
		this.onClick = this.onClick.bind(this);
	}

	onClick() {
		if(this.props.onClick) {
			this.props.onClick(this.props.subCategory);
		}
	}

	render() {
		try {
			var name = this.props.subCategory.name;
			return (
					<button className="channelcat m-2 col-8" onClick={this.onClick}>
						<div className="row mt-1 mb-1">
							<div className="col-8 channelsubcattext">
								{name}
							</div>
							<div className="col-4">
								<EkamChannelCategoryIcon name={"PLAY"} size={"1x"}/>
							</div>
						</div>
					</button>
			);
		} catch (err) {
			console.log("EkamRoomIcon Error:",err.message);
			return <div></div>;
		}
	}
}

class EkamChannelBox extends React.Component {
  	constructor(props) {
		super(props);
		this.onClick = this.onClick.bind(this);
	}

	onClick() {
		if(this.props.onClick) {
			this.props.onClick(this.props.channel);
		}
	}

	render() {
		try {
			var name = this.props.channel.name;
			return (
					<div className="m-3" onClick={this.onClick}>
						<div className="row">
							<div className="col-8 ms-3 my-auto text-start">
								{name}
							</div>
							<div className="col-2">
								<WebSocketContext.Consumer>
								{ 
									sendMessage => <EkamChannelButton device={this.props.device} bkey={"KEY_CHANNEL"} sendMessage={sendMessage} number={this.props.channel.number} name={this.props.channel.name}/> 
								}
								</WebSocketContext.Consumer>
							</div>
						</div>
					</div>
			);
		} catch (err) {
			console.log("EkamRoomIcon Error:",err.message);
			return <div></div>;
		}
	}
}

class EkamChannelPage extends React.Component {
  	constructor(props) {
		super(props);
		this.onCloseClick = this.onCloseClick.bind(this);
		this.onBackClick = this.onBackClick.bind(this);
	}

	onCloseClick() {
		if(this.props.onClick) {
			this.props.onClick(null, "CLOSE");
		}
	}

	onBackClick() {
		if(this.props.onClick) {
			this.props.onClick(null, "BACK");
		}
	}
	render() {
		var channels = [];
		var name = "Channels";
		if(this.props.subCategory)
			channels = this.props.subCategory.channels;
		else
			return <div> </div>;
		if(this.props.name)
			name = this.props.name;
		var cols = [];
		for(var i=0; i<channels.length; i++) {
			cols.push(<EkamChannelBox device={this.props.device} channel={channels[i]} key={i} onClick={this.props.onClick}/>);
		}
		return <div className="pb-2 pt-2 mt-4 mb-3 pb-3 channelcat">
					<div className="row mt-2 mb-3">
						<div className="col-1"> </div>
						<button className="col-2 btn" onClick={this.onBackClick}>
							<EkamChannelCategoryIcon name={"BACK"}/>
						</button>
						<div className="col-6 my-auto">
							<div className="channelsubcattext">
								{name}
							</div>
						</div>
						<button className="col-2 btn" onClick={this.onCloseClick}>
							<EkamChannelCategoryIcon name={"CLOSE"}/>
						</button>
					</div>
					{cols}
				</div>;
	}
}

class EkamChannelInput extends React.Component {
  	constructor(props) {
		super(props);
		this.state = {number:1};
		this.onChange = this.onChange.bind(this);
	}
	onChange(event) {
		if(!isNaN(+event.target.value))
			this.setState({number:event.target.value});
	}
	render() {
		return <div className="pb-1 pt-1 ms-3 me-3 mt-2 mb-1 pb-1 channelbox">
					<div className="row mt-2 mb-3">
						<div className="col my-auto">
							<div className="channelsubcattext">
								{"Enter Channel:"}
							</div>
						</div>
					</div>
					<div className="row mt-2 mb-3">
						<div className="col-2"/>
						<div className="col-4 my-auto">
							<input type="text" maxLength={4} onChange={this.onChange} className="form-control-plaintext channeltext channelinput" id="channel" value={this.state.number}/>
						</div>
						<div className="col-5">
							<WebSocketContext.Consumer>
							{ 
								sendMessage => <EkamChannelButton device={this.props.device} bkey={"KEY_CHANNEL"} sendMessage={sendMessage} number={this.state.number} name={""}/> 
							}
							</WebSocketContext.Consumer>
						</div>
					</div>
				</div>;
	}
}

class EkamChannelCard extends React.Component {
  	constructor(props) {
		super(props);
		this.onCategoryClick = this.onCategoryClick.bind(this);
		this.onSubCategoryClick = this.onSubCategoryClick.bind(this);
		this.onChannelClick = this.onChannelClick.bind(this);
		this.state = {page:"home", category:null, subcategory:null};
	}

	onCategoryClick(category) {
		console.log("category:",category.name,"clicked!");
		if(category.subCategories.length === 1) {
			this.setState({page:"channels", category:category, subCategory:category.subCategories[0]});
		} else {
			this.setState({page:"category", category:category});
		}
	}

	onSubCategoryClick(subCategory, btnName) {
		if(subCategory === null) {
			if((btnName === "CLOSE") || (btnName === "BACK")) {
				this.setState({page:"home"});
				return
			}
			return
		}
		console.log("subCategory:",subCategory.name,"clicked!");
		this.setState({page:"channels", subCategory:subCategory});
	}

	onChannelClick(channel, btnName) {
		if(channel === null) {
			if((btnName === "CLOSE")) {
				this.setState({page:"home"});
				return
			}
			if((btnName === "BACK")) {
				if(this.state.category.subCategories.length === 1) {
					this.setState({page:"home"});
				} else {
					this.setState({page:"category"});
				}
				return
			}
			return
		}
	}

	render() {
		var categories = this.props.device.channelCategories;
		if(!categories)
			return <div> </div>;
		var page = <div> </div>;
		switch(this.state.page) {
			case "home":
				page = <EkamChannelHomePage channelCategories={categories} onClick={this.onCategoryClick} />;
				break;
			case "category":
				page = <EkamCategoryPage category={this.state.category} onClick={this.onSubCategoryClick} />;
				break;
			case "channels":
				var name = this.state.category.name + "-" + this.state.subCategory.name;
				page = <EkamChannelPage device={this.props.device} name={name} subCategory={this.state.subCategory} onClick={this.onChannelClick} />
				break;
			default:
				break;
		}
		return <div> 
					<EkamChannelInput device={this.props.device}/>
					{page}
				</div>;
	}
}

export default EkamChannelCard;
