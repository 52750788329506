import React from 'react';
//import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import Switch from "react-switch";
import './App.css';
import store from './app/store';
import { setNetworkState } from './features/network/networkSlice.js';

class EkamSwitch extends React.Component {
  	constructor(props) {
		super(props);
		this.onClick = this.onClick.bind(this);
		this.getId = this.getId.bind(this);
		this.updateStateFromStore = this.updateStateFromStore.bind(this);
		this.state = {switchState:"UNKNOWN", refreshState:"NORMAL"};
	}

	onClick() {
		if(!this.props.device)
			return;
		if(this.state.refreshState === "INPROGRESS")
			return;

		var gpioState = this.props.device.gpioNumber & 0xfe;
		switch(this.state.switchState) {
			case "ON":
				break;
			case "OFF":
				gpioState = this.props.device.gpioNumber & 0xff;
				break;
			default:
				gpioState = this.props.device.gpioNumber & 0xff;
				break;
		}

		var gpioNumber = this.props.device.gpioNumber >> 16;
		var iotarg = {
			deviceid: this.props.device.deviceId,
			buttonid: gpioNumber,
			value: gpioState,
		};

		var id = this.getId();
		var storearg = {
			id: id,
			value: "INPROGRESS",
		};
		store.dispatch(setNetworkState(storearg));
		if(this.props.sendMessage)
			this.props.sendMessage("set-switch-state", iotarg, id, 8000);

	}

	getId() {
		var id = this.props.device.deviceId + "-" + ((this.props.device.gpioNumber >> 1) & 0x7f);
		var relayNumber = -1;
		if(this.props.device.relayNumber)
			relayNumber = this.props.device.relayNumber;
		var statusIndex = relayNumber;
		if(relayNumber !== -1) {
			switch(relayNumber) {
				case 2: statusIndex = 3; break;
				case 3: statusIndex = 2; break;
				case 6: statusIndex = 7; break;
				case 7: statusIndex = 6; break;
				case 10: statusIndex = 11; break;
				case 11: statusIndex = 10; break;
				case 14: statusIndex = 15; break;
				case 15: statusIndex = 14; break;
				default:
					break;
			}
			if(relayNumber >= 8)
				statusIndex += 8;
			id = this.props.device.deviceId + "-" + statusIndex;
		}

		return id;
	}

	updateStateFromStore() {
		var state = store.getState();
		if(this.props.device) {
			var id = this.getId();

			if(state.switchState) {
				var swState = state.switchState[id];
				if(swState) {
					//console.log("state:",JSON.stringify(state));
					//console.log("id:",id,"state:",swState);
					this.setState({switchState:swState});
				}
			}

			if(state.networkState) {
				var networkState = state.networkState[id];
				if(networkState) {
					this.setState({refreshState:networkState});
				}
			}
		}
	}

	componentDidMount() {
		this.unsubscribeStore = store.subscribe(this.updateStateFromStore);
		this.updateStateFromStore();
	}

	componentWillUnmount() {
		this.unsubscribeStore();
	}

	render() {
		var uncheckedHandleIcon = null;
		var checkedHandleIcon = null;
		var uncheckedIcon = false;
		var checkedIcon = false;
		var offHandleColor="#efefef";
		var onHandleColor="#32ce53";

		var checked = false;
		switch(this.state.switchState) {
			case "ON":
				checked = true;
				break;
			case "OFF":
				checked = false;
				break;
			default:
				offHandleColor="#2d2111";
				checked = false;
				break;
		}
		
		if(this.state.refreshState === "INPROGRESS") {
			switch(this.state.switchState) {
				case "ON":
					offHandleColor="#dcdc58";
					checked = false;
					break;
				default:
					onHandleColor="#dcdc58";
					checked = true;
					break;
			}
		}

		//console.log("Switch Render: swState:",this.state.switchState);

		if(this.props.box) {
			onHandleColor="#ecd63c";
			offHandleColor="#2d2111";
			uncheckedHandleIcon=
				<div
					style={{
					  display: "flex",
					  justifyContent: "center",
					  alignItems: "center",
					  height: "100%",
					  fontSize: 20
					}}
			/>;
			checkedHandleIcon=
				<div
					style={{
					  display: "flex",
					  justifyContent: "center",
					  alignItems: "center",
					  height: "100%",
					  fontSize: 18
					}}
				  />;
			return(
					<Switch
						checked={checked}
						onChange={this.onClick}
						handleDiameter={26}
						offColor="#c5b8a6"
						onColor="#c5b8a6"
						offHandleColor={offHandleColor}
						onHandleColor={onHandleColor}
						height={28}
						width={58}
						borderRadius={6}
						activeBoxShadow="0px 0px 1px 2px #fefefe"
						uncheckedIcon={false}
						checkedIcon={false}
						uncheckedHandleIcon={uncheckedHandleIcon}
						checkedHandleIcon={checkedHandleIcon}
					  />
				);
		}

        return(
				<Switch
					checked={checked}
					onChange={this.onClick}
					onColor="#aae2b6"
					onHandleColor={onHandleColor}
					offHandleColor={offHandleColor}
					handleDiameter={24}
					uncheckedIcon={uncheckedIcon}
					checkedIcon={checkedIcon}
					uncheckedHandleIcon={uncheckedHandleIcon}
					checkedHandleIcon={checkedHandleIcon}
					boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
					activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
					height={14}
					width={40}/>
			);
	}
}

export default EkamSwitch;
