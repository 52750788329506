import { combineReducers } from 'redux'
import { configureStore } from '@reduxjs/toolkit'
import switchStateReducer from '../features/switch/switchSlice'
import sensorStateReducer from '../features/sensor/sensorSlice'
import networkStateReducer from '../features/network/networkSlice'

const rootReducer = combineReducers({ switchState: switchStateReducer, sensorState: sensorStateReducer, networkState: networkStateReducer});

export default configureStore({
  reducer: rootReducer,
})
