import { createSlice } from '@reduxjs/toolkit'

export const networkSlice = createSlice({
  name: 'network',
  initialState: {},
  reducers: {
    setNetworkState(state, action) {
		//console.log("REDUCER State:",state);
		//console.log("REDUCER Action:",action);
		var {id, value} = action.payload;
		state[id] = value;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setNetworkState } = networkSlice.actions

export default networkSlice.reducer
