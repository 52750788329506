import React from 'react';
//import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import Switch from "react-switch";
import './App.css';
import store from './app/store';
import { setNetworkState } from './features/network/networkSlice.js';

class EkamSceneSwitch extends React.Component {
  	constructor(props) {
		super(props);
		this.onClick = this.onClick.bind(this);
		this.updateStateFromStore = this.updateStateFromStore.bind(this);
		this.state = {sceneState:"OFF"};
	}

	onClick() {
		if(!this.props.scene)
			return;
		if(this.state.sceneState === "INPROGRESS")
			return;

		var iotarg = {
			deviceid: this.props.scene.deviceId,
			name: this.props.scene.name,
			sceneId: this.props.scene.sceneId,
		};
		console.log("Scene:",JSON.stringify(this.props.scene));
		var id = this.props.scene.deviceId + "-scene-" + this.props.scene.sceneId;
		var storearg = {
			id: id,
			value: "INPROGRESS",
		};
		store.dispatch(setNetworkState(storearg));
		if(this.props.sendMessage)
			this.props.sendMessage("run-scene", iotarg, id, 8000);
	}

	updateStateFromStore() {
		var state = store.getState();
		if(this.props.scene) {
			//console.log("state:",JSON.stringify(state));
			if(state.networkState) {
				var id = this.props.scene.deviceId + "-scene-" + this.props.scene.sceneId;
				var networkState = state.networkState[id];
				if(networkState) {
					this.setState({sceneState:networkState});
				}
			}
		}
	}

	componentDidMount() {
		this.unsubscribeStore = store.subscribe(this.updateStateFromStore);
		this.updateStateFromStore();
	}

	componentWillUnmount() {
		this.unsubscribeStore();
	}

	render() {
		var uncheckedHandleIcon = null;
		var checkedHandleIcon = null;
		var offHandleColor="#efefef";
		var onHandleColor="#32ce53";

		var checked = false;
		switch(this.state.sceneState) {
			case "INPROGRESS":
				checked = true;
				break;
			default:
				checked = false;
				break;
		}
		
		//console.log("Switch Render: sceneState:",this.state.sceneState);
		onHandleColor="#ecd63c";
		offHandleColor="#2d2111";
		uncheckedHandleIcon=
			<div
				style={{
				  display: "flex",
				  justifyContent: "center",
				  alignItems: "center",
				  height: "100%",
				  fontSize: 20
				}}
		/>;
		checkedHandleIcon=
			<div
				style={{
				  display: "flex",
				  justifyContent: "center",
				  alignItems: "center",
				  height: "100%",
				  fontSize: 18
				}}
			  />;
		return(
				<Switch
					checked={checked}
					onChange={this.onClick}
					handleDiameter={26}
					offColor="#c5b8a6"
					onColor="#c5b8a6"
					offHandleColor={offHandleColor}
					onHandleColor={onHandleColor}
					height={28}
					width={58}
					borderRadius={6}
					activeBoxShadow="0px 0px 1px 2px #fefefe"
					uncheckedIcon={false}
					checkedIcon={false}
					uncheckedHandleIcon={uncheckedHandleIcon}
					checkedHandleIcon={checkedHandleIcon}
				  />
			);
	}
}

export default EkamSceneSwitch;
