import { createSlice } from '@reduxjs/toolkit'

export const sensorSlice = createSlice({
  name: 'sensor',
  initialState: {},
  reducers: {
    setSensorState(state, action) {
		//console.log("SENSOR REDUCER State:",state);
		//console.log("SENSOR REDUCER Action:",action);
		var {deviceid, sensorid, value} = action.payload;
		var id = deviceid + "-sensor-" + sensorid;
		state[id] = value;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setSensorState } = sensorSlice.actions

export default sensorSlice.reducer
