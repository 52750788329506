import React from 'react';
//import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import EkamIrButton from "./ekamirbutton.js";
import {WebSocketContext} from './websocket.js';
import './App.css';
import './splide.css';

class EkamIrMainCard extends React.Component {
	render() {
		var hasMainKey = false;
		var hasTitleKey = false;
		var buttons = this.props.device.buttons;
		var guideKey = "KEY_MENU";
		if(this.props.device.remoteType === 3)
			guideKey = "KEY_INPUT";

		for(var i=0; i<buttons.length; i++) {
			switch(buttons[i].key) {
				case "KEY_OK":
				case "KEY_RIGHT":
				case "KEY_LEFT":
				case "KEY_UP":
				case "KEY_DOWN":
				case "KEY_VOLUME_UP":
				case "KEY_VOLUME_DOWN":
				case "KEY_CH_UP":
				case "KEY_CH_DOWN":
					hasMainKey = true;
					break;
				default:
					break;
			}
		}

		for(i=0; i<buttons.length; i++) {
			switch(buttons[i].key) {
				case "KEY_MUTE":
				case "KEY_BACK":
				case "KEY_POWER":
					hasTitleKey = true;
					break;
				default:
					break;
			}
			if(buttons[i].key === guideKey) {
				hasTitleKey = true;
				break;
			}
		}

		var mainCard = <div> </div>;
		var titleCard = <div> </div>;

		if(!hasTitleKey && !hasMainKey)
			return <div></div>;

		if(hasMainKey) {
			mainCard =
					<div>
						<div className="pt-2">
						</div>
						<div className="row mt-4 mb-2">
							<div className="col d-flex justify-content-start ms-3">
								<WebSocketContext.Consumer>
									{ sendMessage => <EkamIrButton device={this.props.device} bkey={"KEY_VOLUME_UP"} sendMessage={sendMessage}/> }
								</WebSocketContext.Consumer>
							</div>
							<div className="col d-flex justify-content-end me-3">
								<WebSocketContext.Consumer>
									{ sendMessage => <EkamIrButton device={this.props.device} bkey={"KEY_CH_UP"} sendMessage={sendMessage}/> }
								</WebSocketContext.Consumer>
							</div>
						</div>
						<div className="mx-auto">
							<div className="mx-auto okbuttonouter">
								<div className="mx-auto okbuttoninner">
								</div>
								<div className="okbutton">
									<WebSocketContext.Consumer>
										{ sendMessage => <EkamIrButton device={this.props.device} bkey={"KEY_OK"} sendMessage={sendMessage}/> }
									</WebSocketContext.Consumer>
								</div>
								<div className="rightbutton">
									<WebSocketContext.Consumer>
										{ sendMessage => <EkamIrButton device={this.props.device} bkey={"KEY_RIGHT"} sendMessage={sendMessage}/> }
									</WebSocketContext.Consumer>
								</div>
								<div className="leftbutton">
									<WebSocketContext.Consumer>
										{ sendMessage => <EkamIrButton device={this.props.device} bkey={"KEY_LEFT"} sendMessage={sendMessage}/> }
									</WebSocketContext.Consumer>
								</div>
								<div className="upbutton my-auto">
									<WebSocketContext.Consumer>
										{ sendMessage => <EkamIrButton device={this.props.device} bkey={"KEY_UP"} sendMessage={sendMessage}/> }
									</WebSocketContext.Consumer>
								</div>
								<div className="downbutton">
									<WebSocketContext.Consumer>
										{ sendMessage => <EkamIrButton device={this.props.device} bkey={"KEY_DOWN"} sendMessage={sendMessage}/> }
									</WebSocketContext.Consumer>
								</div>
							</div>
						</div>
						<div className="row ml-1 mr-1">
							<div className="col d-flex justify-content-start ms-3">
								<WebSocketContext.Consumer>
									{ sendMessage => <EkamIrButton device={this.props.device} bkey={"KEY_VOLUME_DOWN"} sendMessage={sendMessage}/> }
								</WebSocketContext.Consumer>
							</div>
							<div className="col d-flex justify-content-end me-3">
								<WebSocketContext.Consumer>
									{ sendMessage => <EkamIrButton device={this.props.device} bkey={"KEY_CH_DOWN"} sendMessage={sendMessage}/> }
								</WebSocketContext.Consumer>
							</div>
						</div>
						<div className="pb-2">
						</div>
					</div>
		}

		if(hasTitleKey) {
			titleCard =
					<div className="row mt-4 mb-2 ml-1 mr-1">
						<div className="col m-auto">
							<WebSocketContext.Consumer>
								{ sendMessage => <EkamIrButton device={this.props.device} bkey={guideKey} sendMessage={sendMessage}/> }
							</WebSocketContext.Consumer>
						</div>
						<div className="col m-auto">
							<WebSocketContext.Consumer>
								{ sendMessage => <EkamIrButton device={this.props.device} bkey={"KEY_MUTE"} sendMessage={sendMessage}/> }
							</WebSocketContext.Consumer>
						</div>
						<div className="col m-auto">
							<WebSocketContext.Consumer>
								{ sendMessage => <EkamIrButton device={this.props.device} bkey={"KEY_BACK"} sendMessage={sendMessage}/> }
							</WebSocketContext.Consumer>
						</div>
						<div className="col m-auto">
							<WebSocketContext.Consumer>
								{ sendMessage => <EkamIrButton device={this.props.device} bkey={"KEY_POWER"} sendMessage={sendMessage}/> }
							</WebSocketContext.Consumer>
						</div>
					</div>
		}
		
        return(
				<div className="mb-4">
					<div className="pt-2"> </div>
					{titleCard}
					{mainCard}
				</div>
          );
	}
}

export default EkamIrMainCard;
