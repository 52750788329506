import React from 'react';
//import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './App.css';
import store from './app/store';

class EkamFanSpeed extends React.Component {
  	constructor(props) {
		super(props);
		this.updateStateFromStore = this.updateStateFromStore.bind(this);
		this.state = {speed:"-"};
	}

	updateStateFromStore() {
		var state = store.getState();
		var id = this.props.device.deviceId + "-fan-speed-" + ((this.props.device.gpioNumber >> 1) & 0x7f);

		if(state.networkState) {
			var networkState = state.networkState[id];
			if(networkState) {
				this.setState({speed:networkState});
			}
		}
	}

	componentDidMount() {
		this.unsubscribeStore = store.subscribe(this.updateStateFromStore);
		this.updateStateFromStore();
	}

	componentWillUnmount() {
		this.unsubscribeStore();
	}

	render() {
        return(
				<h5 className="col mx-auto mt-2">{this.state.speed}</h5>
			);
	}
}

export default EkamFanSpeed;
