import React from 'react';
//import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCouch, faBed, faHome, faStream, faDoorOpen, faCocktail, faChild, faChair, faTree, faFilm } from '@fortawesome/free-solid-svg-icons'
//import { faMinusSquare, faPlusSquare } from '@fortawesome/free-regular-svg-icons'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import Loader from "react-loader-spinner";
import EkamDeviceCard from "./ekamdevice.js";
import {WebSocketContext} from './websocket.js';
import {EkamScenePageSlider, EkamSceneViewer} from './ekamscene.js';
import {EkamCategoryIcon, EkamError} from './ekamutils.js';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import './App.css';
import './splide.css';

class EkamRoomIcon extends React.Component {
	render() {
		try {
			var roomName = this.props.roomName.toUpperCase();
			var icon = faHome;
			switch(roomName) {
				case "HALL":
					icon = faCouch;
					break;
				case "BEDROOM":
					icon = faBed;
					break;
				case "SCENES":
					icon = faStream;
					break;
				case "LIVING":
					icon = faDoorOpen;
					break;
				case "KITCHEN":
					icon = faCocktail;
					break;
				case "MASTER BEDROOM":
					icon = faBed;
					break;
				case "CHILDREN BEDROOM":
					icon = faChild;
					break;
				case "TERRACE":
					icon = faChair;
					break;
				case "GARDEN":
					icon = faTree;
					break;
				case "ENTERTAINMENT":
					icon = faFilm;
					break;
				default:
					break;
			}
			return (
				<div className="col-auto roomicon">
					<FontAwesomeIcon icon={icon} size="lg"/>
				</div>
			);
		} catch (err) {
			console.log("EkamRoomIcon Error:",err.message);
			return <div></div>;
		}
	}
}

class EkamRoomCard extends React.Component {
  	constructor(props) {
		super(props);
		this.onClick = this.onClick.bind(this);
	}

	onClick() {
		if(this.props.onClick)
			this.props.onClick(this.props.room);
		else if(this.props.onSceneClick)
			this.props.onSceneClick(this.props.scenes);
	}

	render() {
		try {
			var room = this.props.room;
			return (
				<SplideSlide>
					<div className="roomcard" onClick={this.onClick}>
						<div className="row ms-1 me-1">
							<EkamRoomIcon roomName={room.name}/>
							<h5 className="col-auto d-flex justify-content-start me-1 mt-auto pt-2">{room.name}</h5>
						</div>
					</div>
				</SplideSlide>
			);
		} catch (err) {
			console.log("EkamRoomCard Error:",err.message);
			return <div></div>;
		}
	}
}


class EkamCategoryCard extends React.Component {
  	constructor(props) {
		super(props);
		this.onClick = this.onClick.bind(this);
	}

	onClick() {
		if(this.props.onClick)
			this.props.onClick(this.props.json);
	}

	render() {
		try {
			var layout = this.props.json;
			return (
				<SplideSlide>
					<div className="categorycard" onClick={this.onClick}>
						<div className="row ms-1 me-1">
							<EkamCategoryIcon name={layout.category}/>
							<h5 className="col-auto mt-auto pt-2">{layout.category}</h5>
						</div>
					</div>
				</SplideSlide>
			);
		} catch (err) {
			console.log("EkamCategoryCard Error:",err.message);
			return <div></div>;
		}
	}
}

class EkamCategorySlider extends React.Component {
  	constructor(props) {
		super(props);
		this.onCategoryClick = this.onCategoryClick.bind(this);
	}

	onCategoryClick(json) {
		if(this.props.onCategoryClick) {
			this.props.onCategoryClick(json);
		}
	}

	render() {
		try {
			var categories = this.props.json;
			var card;
			if(categories) {
				var categoryRows = [];
				for(var i=0; i<categories.length; i++) {
					card = <EkamCategoryCard json={{category:categories[i].name,devices:categories[i].devices}} key={categories[i].name} onClick={this.onCategoryClick}/>;
					categoryRows.push(card);
				}
			}
			return ( 
						<Splide
							options={ {
									type : 'slide',
									rewind : true,
									autoWidth: true,
									perMove: 2
								} }
							>
							{categoryRows}
						</Splide>
					);
		} catch (err) {
			console.log("EkamCategorySlider Error:",err.message);
			return <div></div>;
		}
	}
}

class EkamDeviceSlider extends React.Component {
	render() {
		try {
			var json = this.props.json;
			var devices = null;
			if(json)
				devices = json.devices;
			var cards = [];
			if(devices) {
				var category = json.category;
				for (var i = 0; i < devices.length; i++) {
					var card = <EkamDeviceCard device={devices[i]} category={category} key={devices[i].deviceId + "-" + i}/>;
					cards.push(card);
				}
			}
			return ( 
						<div className="card-deck mx-auto justify-content-center text-center mt-4">
							{cards}
						</div>
					);
		} catch (err) {
			console.log("EkamDeviceSlider Error:",err.message);
			return <div></div>;
		}
	}
}

class EkamRoomSlider extends React.Component {

  	constructor(props) {
		super(props);
		this.onRoomClick = this.onRoomClick.bind(this);
		this.onSceneClick = this.onSceneClick.bind(this);
	}

	onRoomClick(roomjson) {
		if(this.props.onRoomClick) {
			this.props.onRoomClick(roomjson);
		}
	}

	onSceneClick(scenejson) {
		if(this.props.onSceneClick) {
			this.props.onSceneClick(scenejson);
		}
	}

	render() {
		try {
			//console.log("JSON",this.props.json);
			var layout = this.props.json;
			var rows = [];
			if(layout) {
				var rooms = layout.rooms;
				var scenes = layout.scenes;
				for (var i = 0; i < rooms.length; i++) {
					var card = <EkamRoomCard room={rooms[i]} key={i} id={"home-"+i} onClick={this.onRoomClick}/>;
					rows.push(card);
				} 
				if(layout.scenes) {
					card = <EkamRoomCard room={{name:"Scenes"}} scenes={scenes} key={i} id={"home-"+(i)} onSceneClick={this.onSceneClick}/>;
					rows.push(card);
				}
			}

			return ( 
						<Splide
							options={ {
									type : 'slide',
									rewind : true,
									autoWidth: true,
									perMove: 2
								} }
							>
							{rows}
						</Splide>
					);
		} catch (err) {
			console.log("EkamRoomSlider Error:",err.message);
			return <div></div>;
		}
	}
}

class EkamUI extends React.Component {
  	constructor(props) {
		super(props);
		this.onRoomClick = this.onRoomClick.bind(this);
		this.onCategoryClick = this.onCategoryClick.bind(this);
		this.updateState = this.updateState.bind(this);
		this.onSceneClick = this.onSceneClick.bind(this);
		this.onScenePageClick = this.onScenePageClick.bind(this);

		this.state = {home:null,categories:null,devices:null};
		this.props.setUpdateStateFn(this.updateState);
	}

	updateState(json) {
		//console.log("updateState:",json);

		if(json) {
			if((json.action === "show-progress") || (json.action === "error")) {
				this.setState({home:null, action:json.action, message:json.message});
				return;
			}

			this.setState({home:json});
			var rooms = json.rooms;
			if(rooms && rooms[0]) {
				this.onRoomClick(rooms[0]);
			}
		}
	}

	onRoomClick(room) {
		var categories = room.categories;
		if(categories) {
			this.setState({categories:categories, scenes:null});
			if(categories[0]) {
				if(categories[0].devices) {
					this.setState({devices:categories[0].devices, category:categories[0].name});
				}
			}
		}
	}

	onSceneClick(scenes) {
		if(scenes) {
			this.setState({scenes:scenes});
			if(scenes[0]) {
				this.setState({page:scenes[0]});
			}
		}
	}

	onScenePageClick(page) {
		if(page) {
			this.setState({page:page});
		}
	}

	onCategoryClick(json) {
		this.setState({devices:json.devices, category:json.category});
	}

	render() {
		try {
			if(this.state && this.state.home) {
				var categories = null;
				var devices = null;
				var home = null;
				if(this.state) {
					home = this.state.home;
					categories = this.state.categories;
					devices = this.state.devices;
				}

				if(Object.keys(home).length === 0) {
					return(
							<div className="myloader">
								<div className="card messagebox">
									<div className="card-header">
										<h4>Upload required!</h4>
									</div>
									<div className="card-body">
										<p> Please install latest IRBot app from <a href="https://drive.google.com/file/d/1vRTkSTRLB250uxWuEiCQZ6WzeKnlzEoi/view?usp=sharing">here</a> and use Settings->Upload Technical Support option to upload the profiles.</p>
									</div>
								</div>
							</div>);
				}

				if(!this.state.scenes) {
						return ( 
							<div>
								<EkamRoomSlider json={home} onRoomClick={this.onRoomClick} onSceneClick={this.onSceneClick}/>
								<EkamCategorySlider json={categories} onCategoryClick={this.onCategoryClick}/>
								<EkamDeviceSlider json={{devices:devices, category:this.state.category}}/>
								<div className="bottomspace"/>
							</div>
						);
				} else {
						return ( 
							<div>
								<EkamRoomSlider json={home} onRoomClick={this.onRoomClick} onSceneClick={this.onSceneClick}/>
								<EkamScenePageSlider json={this.state.scenes} onScenePageClick={this.onScenePageClick}/>
								<EkamSceneViewer page={this.state.page}/>
								<div className="bottomspace"/>
							</div>
						);
				}
			} else {
				if(this.state && this.state.action && this.state.action === "error" && this.state.message) {
					return(
							<WebSocketContext.Consumer>
								{ sendMessage => <EkamError message={this.state.message} sendMessage={sendMessage}/> }
							</WebSocketContext.Consumer>
						);
				} else {
					return ( <div className="myloader">
								<Loader
									type="Circles"
									color="#5a442a"
									height={100}
									width={100}
								/> 
							</div>);
				}
			}
		} catch (err) {
			console.log("EkamUI Error:",err.message);
			return(
					<div className="myloader">
						<div className="card messagebox">
							<div className="card-header">
								<h4>Internal Error!</h4>
							</div>
							<div className="card-body">
								<p>{err.message}</p>
							</div>
						</div>
					</div>);
		}
	}
}

export default EkamUI;
