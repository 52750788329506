import React from 'react';
//import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './App.css';
import store from './app/store';
import { setNetworkState } from './features/network/networkSlice.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSync } from '@fortawesome/free-solid-svg-icons'

class EkamSwitchRefresh extends React.Component {
  	constructor(props) {
		super(props);
		this.onClick = this.onClick.bind(this);
		this.getId = this.getId.bind(this);
		this.updateStateFromStore = this.updateStateFromStore.bind(this);
		this.state = {refreshState:"NORMAL"};
	}

	onClick() {
		if(!this.props.device)
			return;
		if(this.state.refreshState === "INPROGRESS")
			return;

		var gpioState = this.props.device.gpioNumber & 0xfe;
		var gpioNumber = this.props.device.gpioNumber >> 16;
		var iotarg = {
			deviceid: this.props.device.deviceId,
			buttonid: gpioNumber,
			value: gpioState,
		};
		var id = this.getId();
		var storearg = {
			id: id,
			value: "INPROGRESS",
		};
		store.dispatch(setNetworkState(storearg));
		if(this.props.sendMessage)
			this.props.sendMessage("get-switch-state", iotarg, id, 8000);
	}

	updateStateFromStore() {
		var state = store.getState();
		if(this.props.device) {
			var id = this.getId();

			if(state.networkState) {
				var networkState = state.networkState[id];
				if(networkState) {
					this.setState({refreshState:networkState});
				}
			}
		}
	}

	getId() {
		var id = this.props.device.deviceId + "-switch-refresh-" + ((this.props.device.gpioNumber >> 1) & 0x7f);
		var relayNumber = -1;
		if(this.props.device.relayNumber)
			relayNumber = this.props.device.relayNumber;
		var statusIndex = relayNumber;
		if(relayNumber !== -1) {
			switch(relayNumber) {
				case 2: statusIndex = 3; break;
				case 3: statusIndex = 2; break;
				case 6: statusIndex = 7; break;
				case 7: statusIndex = 6; break;
				case 10: statusIndex = 11; break;
				case 11: statusIndex = 10; break;
				case 14: statusIndex = 15; break;
				case 15: statusIndex = 14; break;
				default:
					break;
			}
			if(relayNumber >= 8)
				statusIndex += 8;
			id = this.props.device.deviceId + "-switch-refresh-" + statusIndex;
		}

		return id;
	}

	componentDidMount() {
		this.unsubscribeStore = store.subscribe(this.updateStateFromStore);
		this.updateStateFromStore();
	}

	componentWillUnmount() {
		this.unsubscribeStore();
	}

	render() {
		var color = 'var(--my-text-color)';
		if(this.state.refreshState === "INPROGRESS") {
			color = "yellow";
		}

        return(
				<button className="btn" onClick={this.onClick} >
					<FontAwesomeIcon icon={faSync} size="1x" color={color}/>
				</button>
			);
	}
}

export default EkamSwitchRefresh;
