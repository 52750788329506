import React from 'react';
//import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './App.css';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSync} from '@fortawesome/free-solid-svg-icons'

import store from './app/store';
import { setNetworkState } from './features/network/networkSlice.js';
import EkamSwitch from "./ekamswitch.js";
import {WebSocketContext} from './websocket.js';

class EkamSensorRefreshButton extends React.Component {
	render() {
		var sensorButton;
		if(this.props.state === "NORMAL") {
			sensorButton =	
				<button className="refreshbutton mb-2" onClick={this.props.onClick}>
					<FontAwesomeIcon className="h6 mt-3" icon={faSync} size="3x"/>
				</button>;
		} else {
			sensorButton =	
				<button className="refreshbuttonprogress mb-2" onClick={this.props.onClick}>
					<FontAwesomeIcon className="h6 mt-3" icon={faSync} size="3x"/>
				</button>;
		}
		return sensorButton;
	}
}

class EkamSensorSwitchCard extends React.Component {
	render() {
		if(this.props.sensorType === 25 ) {
			return(
					<div className="col m-auto d-flex justify-content-end me-4">
						<WebSocketContext.Consumer>
							{ sendMessage => <EkamSwitch device={this.props.device} sendMessage={sendMessage}/> }
						</WebSocketContext.Consumer>
					</div>
			  );
		} else {
			return (
				<div className="col m-auto d-flex justify-content-end"/>
			);
		}
	}
}

class EkamSensor extends React.Component {
  	constructor(props) {
		super(props);
		this.onClick = this.onClick.bind(this);
		this.updateStateFromStore = this.updateStateFromStore.bind(this);
		this.state = {sensorValue:0xffff, refreshState:"NORMAL"};
	}

	onClick() {
		if(!this.props.device)
			return;
		if(this.state.refreshState === "INPROGRESS")
			return;

		var iotarg = {
			deviceid: this.props.device.deviceId,
		};
		var id = this.props.device.deviceId + "-sensor-" + this.props.device.remoteId;
		if(this.props.sendMessage) {
			this.props.sendMessage("get-sensor-state",iotarg,id,8000);
			var storearg = {
				id: id,
				value: "INPROGRESS",
			};
			store.dispatch(setNetworkState(storearg));
		}
	}

	updateStateFromStore() {
		var state = store.getState();
		if(this.props.device) {
			var id = this.props.device.deviceId + "-sensor-" + this.props.device.remoteId;
			//console.log("updating from store id:",id);
			if(state.sensorState) {
				//console.log("Got full state:",state.sensorState);
				var sensorState = state.sensorState[id];
				//console.log("Got state:",sensorState);
				if(sensorState) {
					//console.log("state:",JSON.stringify(state));
					//console.log("id:",id,"state:",sensorState);
					this.setState({sensorValue:sensorState.sensorValue});
					this.setState({refreshState:sensorState.refreshState});
				}
			}

			if(state.networkState) {
				var networkState = state.networkState[id];
				if(networkState) {
					this.setState({refreshState:networkState});
				}
			}
		}
	}

	componentDidMount() {
		this.unsubscribeStore = store.subscribe(this.updateStateFromStore);
		this.updateStateFromStore();
	}

	componentWillUnmount() {
		this.unsubscribeStore();
	}

	render() {
		var unit = "Units";
		var color = "#6eb6ff";
		var min = 0;
		var max = 0xffff;
		switch(this.props.device.remoteType) {
			case 20: //Temperature
				unit = "\xB0 C";
				color = "#dcdc58";
				min = 0;
				max = 100;
				break;
			case 23: //Humidity
				unit = "%";
				color = "#71bd71";
				min = 0;
				max = 100;
				break;
			case 24: //Weight
				unit = "kg";
				color = "#71bd71";
				min = 0;
				max = 1000;
				break;
			case 25: //Flow
				unit = "L";
				color = "#6eb6ff";
				break;
			default:
				break;
		}
		var sensorValue = this.state.sensorValue;
		//console.log("sensorValue:",sensorValue);
		var sensorValueText = sensorValue + "";
		var sensorPercent = ((sensorValue-min)/(max-min))*100;
		if(sensorValue === 0xffff) {
			sensorValue = 50;
			sensorValueText = "";
			sensorPercent = 50;
		}
		//console.log("sensorPercent:",sensorPercent);
		var mydevice = {deviceId:this.props.device.deviceId, gpioNumber:((this.props.device.remoteId << 16)|(this.props.device.remoteId << 1)|1)};
		var sensorCard = 
				<div className="sensorcard">
					<div className="row mt-4 pb-1">
						<h5 className="col m-auto ms-3 my-2">{this.props.device.name}</h5>
						<EkamSensorSwitchCard sensorType={this.props.device.remoteType} device={mydevice} name={this.props.device.name}/>
					</div>
					<div className="ms-4 me-4 mt-4">
						<div className="ms-3 me-3">
							<CircularProgressbar
								value={sensorPercent}
								text={sensorValueText}
								styles={buildStyles({
								  textColor: "white",
								  pathColor: color,
								})}
							  />
						</div>
					</div>
					<div>
						<h5 className="d-flex justify-content-end me-5 mt-2">{unit}</h5>
					</div>
					<div className="d-flex justify-content-start mb-4 ms-4 mt-4">
						<EkamSensorRefreshButton state={this.state.refreshState} onClick={this.onClick}/>
					</div>
				</div>
		return sensorCard;
	}
}

export default EkamSensor;
