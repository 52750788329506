import React from 'react';
//import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faToggleOn, faFan, faSnowflake, faLightbulb, faTv, faVideo, faTachometerAlt, faStore, faFileAlt, faSync} from '@fortawesome/free-solid-svg-icons'
//import { faMinusSquare, faPlusSquare } from '@fortawesome/free-regular-svg-icons'

class EkamCategoryIcon extends React.Component {
	render() {
		try {
			var name= this.props.name.toUpperCase();
			var icon = faToggleOn;
			switch(name) {
				case "SWITCHES":
					icon = faToggleOn;
					break;
				case "FANS":
					icon = faFan;
					break;
				case "ACS":
					icon = faSnowflake;
					break;
				case "CURTAINS":
					icon = faStore;
					break;
				case "DIMMERS":
					icon = faLightbulb;
					break;
				case "APPLIANCES":
					icon = faTv;
					break;
				case "CAMERAS":
					icon = faVideo;
					break;
				case "SENSORS":
					icon = faTachometerAlt;
					break;
				case "SCENES":
					icon = faFileAlt;
					break;
				default:
					break;
			}
			return (
				<div className="col-auto categoryicon">
					<FontAwesomeIcon icon={icon} size="lg"/>
				</div>
			);
		} catch (err) {
			console.log("EkamCategoryIcon Error:",err.message);
			return <div></div>;
		}
	}
}

class EkamError extends React.Component {
  	constructor(props) {
		super(props);
		this.onRefreshClick = this.onRefreshClick.bind(this);
	}

	onRefreshClick() {
		if(this.props.sendMessage) {
			this.props.sendMessage("refresh","");
		}
	}
	
	render() {
		return(
				<div className="myloader">
					<div className="card messagebox">
						<div className="card-header mt-4">
							<h4>{this.props.message.title}</h4>
						</div>
						<div className="card-body">
							<p>{this.props.message.body}</p>
							<p>Click below to Reload!</p>
						</div>
						<button className="refreshbutton mb-2 mb-4" onClick={this.onRefreshClick}>
							<FontAwesomeIcon className="h6 mt-3" icon={faSync} size="3x"/>
						</button>
					</div>
				</div>
			);
	}
}


export {EkamCategoryIcon, EkamError};
