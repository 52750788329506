import React from 'react';
//import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import EkamIrButton from "./ekamirbutton.js";
import {WebSocketContext} from './websocket.js';
import './App.css';
import './splide.css';
import {
	CircularInput,
	CircularTrack,
	CircularProgress,
	CircularThumb
} from 'react-circular-input';

class EkamIrACCard extends React.Component {
  	constructor(props) {
		super(props);
		this.onChange = this.onChange.bind(this);
		this.gettemp = this.gettemp.bind(this);
		this.state = {value:0.75, temp:27};
	}

	onChange(v) {
		const range = [16, 30]
		// scaled range value
		const temp= Math.round(v * (range[1] - range[0]) + range[0]);
		//console.log("AC Temp:",temp);
		this.setState({value:v, temp:temp});
	}

	gettemp() {
		return this.state.temp;
	}

	render() {
		var hasACKey = false;
		var buttons = this.props.device.buttons;
		for(var i=0; i<buttons.length; i++) {
			if(buttons[i].key.indexOf("KEY_AC") !== -1) {
				hasACKey = true;
				break;
			}
		}

		if(hasACKey === false)
			return <div> </div>;


        return(
				<div className="mb-4">
					<div className="row mt-5 mb-2 ml-1 mr-1">
						<div className="col m-auto">
							<WebSocketContext.Consumer>
								{ sendMessage => <EkamIrButton device={this.props.device} bkey={"KEY_AC_POWER_ON"} sendMessage={sendMessage}/> }
							</WebSocketContext.Consumer>
						</div>
						<div className="col m-auto">
							<WebSocketContext.Consumer>
								{ sendMessage => <EkamIrButton device={this.props.device} bkey={"KEY_AC_POWER_OFF"} sendMessage={sendMessage}/> }
							</WebSocketContext.Consumer>
						</div>
					</div>
					<CircularInput value={this.state.value} onChange={this.onChange} className="p-4">
						<CircularTrack strokeWidth={10} stroke="#efefef"/>
						<CircularProgress strokeWidth={10} stroke="#a58b5a" />
						<CircularThumb fill="#afa692"/>
						<text x={100} y={100} textAnchor="middle" dy="0.3em" fontWeight="bold"fontSize="50" fill="#efefef">
							{this.state.temp}
						</text>
					</CircularInput>
					<div className="row mt-4 mb-2 ml-1 mr-1 pb-3">
						<div className="col m-auto">
							<WebSocketContext.Consumer>
								{ sendMessage => <EkamIrButton device={this.props.device} bkey={"KEY_AC_TEMP"} sendMessage={sendMessage} gettemp={this.gettemp}/> }
							</WebSocketContext.Consumer>
						</div>
					</div>
				</div>
          );
	}
}

export default EkamIrACCard;
