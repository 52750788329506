import { createSlice } from '@reduxjs/toolkit'

export const switchSlice = createSlice({
  name: 'switch',
  initialState: {},
  reducers: {
    setSwitchState(state, action) {
		//console.log("REDUCER State:",state);
		//console.log("REDUCER Action:",action);
		var {deviceid, buttonid, value} = action.payload;
		var id = deviceid + "-" + buttonid;
		//console.log("switchstate["+id+"]="+value);
		state[id] = value;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setSwitchState } = switchSlice.actions

export default switchSlice.reducer
